exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-captacao-js": () => import("./../../../src/pages/captacao.js" /* webpackChunkName: "component---src-pages-captacao-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-br-js": () => import("./../../../src/pages/index.pt-br.js" /* webpackChunkName: "component---src-pages-index-pt-br-js" */),
  "component---src-pages-ouvidoria-js": () => import("./../../../src/pages/ouvidoria.js" /* webpackChunkName: "component---src-pages-ouvidoria-js" */),
  "component---src-pages-privacidade-de-dados-js": () => import("./../../../src/pages/privacidade-de-dados.js" /* webpackChunkName: "component---src-pages-privacidade-de-dados-js" */),
  "component---src-pages-privacidade-js": () => import("./../../../src/pages/privacidade.js" /* webpackChunkName: "component---src-pages-privacidade-js" */)
}

